<template>
    <div>

        <el-main id="main" style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="form" :model="formData" @submit.native.prevent label-width="80px">
                <el-row>
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px;">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：产品报价</strong>
                                <el-checkbox v-if="isMobile" style="padding-left: 25px" v-model="showFrom">显示表单</el-checkbox>
                                <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}</strong>
                            </el-col>
                        </el-row>
                    </div>
                    <el-col :lg="9" :md="9" :sm="24">
                        <div v-show="showFrom">
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="12">
                                    <el-form-item label="日期:">
                                        <el-date-picker
                                                v-model="formData.bill_date"
                                                align="right"
                                                type="date"
                                                size="mini"
                                                :clearable="false"
                                                style="width: 100%"
                                                @keyup.native.enter="$_focusNext('customer')"
                                                :readonly="true">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="12" :md="12" :sm="12">
                                    <el-form-item label="单号:">
                                        <el-input ref="bill_id" v-model="formData.bill_id" :readonly="true" placeholder="自动生成"
                                                  @keyup.native.enter="$_focusNext('customer')" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="12">
                                    <el-form-item label="客户:">
                                        <el-select ref="customer" v-model="formData.customer" style="width: 100%"
                                                   default-first-option remote :remote-method="$_searchCustomerList" clearable
                                                   filterable size="mini" placeholder="可搜索查询"
                                                   @visible-change="(v) => $refs.AddCompany.visibleChange(v, this.$refs['customer'])"
                                                   @keyup.native.enter="$_blurNext('customer'),$_focusNext('remark_warehouse_bill')">
                                            <el-option
                                                    v-for="item in $store.getters.getCustomerList"
                                                    :key="item.id"
                                                    :label="item.customer_name"
                                                    :value="item.customer_name">
                                                <span style="float: left">{{item.customer_name}}</span>
                                                <span v-if="$_getActionKeyListBoolean(['/customers/editVue'])" style="float: right"><i class="el-icon-edit-outline__close el-icon-edit-outline" @click.stop="$_blurNext('customer'),$refs.AddCompany.editRowEvent(item.id),formData.customer='',$store.getters.getCustomerList=[]"/></span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="12" :md="12" :sm="12">
                                    <el-form-item label="记录员 :">
                                        <el-input ref="registrar" :readonly="true" v-model="formData.registrar"
                                                  style="width: 100%"
                                                  placeholder="自动生成" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="12">
                                    <el-form-item label="备注 :">
                                        <el-input ref="remark_warehouse_bill" id="remark_warehouse_bill" v-model="formData.remark_warehouse_bill"
                                                  style="width: 100%"
                                                  placeholder="请输入" size="mini"
                                                  @keyup.native.enter="$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :sm="12">
                                    <el-tooltip class="item" effect="dark"
                                                :content="`修改时间:${formData.last_date_warehouse_bill}`" placement="bottom-end" :open-delay = "750">
                                        <el-form-item label="修改人 :">
                                            <el-input ref="last_name_warehouse_bill" :readonly="true"
                                                      v-model="formData.last_name_warehouse_bill" style="width: 100%"
                                                      placeholder="自动生成" size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :lg="4" :sm="4">
                                    <el-checkbox ref="isTicket" v-model="formData.is_ticket"
                                                 style=" margin-top: 10px; margin-left: 10px; ">开票
                                    </el-checkbox>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="8" :sm="12">
                                    <el-form-item label="合计数量:">
                                        <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :sm="12">
                                    <el-form-item label="总理论重:">
                                        <el-input :readonly="true" placeholder="自动计算"
                                                  v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :sm="12">
                                    <el-form-item label="合计金额:">
                                        <el-input :readonly="true" placeholder="自动计算"
                                                  v-model="formData.total_amount" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="12" :sm="12">
                                    <vxe-toolbar style="float: left" custom ref="xToolbar2">
                                    </vxe-toolbar>
                                   <!-- <el-button style=" margin-top: 12px; margin-left: 12px; " type="primary"
                                               :disabled="formData.bill_id == null"
                                               round @click="createDd" size="mini">转销售订单
                                    </el-button>-->
                                    <el-button style=" margin-top: 12px; margin-left: 12px;" type="success"
                                               round @click="$router.push('/productQuotation')" size="mini">新建
                                    </el-button>
                                    <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                                    </el-button>
                                </el-col>
                                <el-col :lg="12" :sm="12">
                                    <vxe-toolbar style="float: right" custom ref="xToolbar3">
                                    </vxe-toolbar>
                                    <vxe-toolbar style="float: right" custom ref="xToolbar4">
                                    </vxe-toolbar>
                                    <vxe-toolbar style="float: right" custom ref="xToolbar5">
                                    </vxe-toolbar>
                                    <el-button style=" margin-top: 12px; margin-left: 12px;float: right" id="createBill" type="danger" plain
                                               :disabled="this.loading"
                                               round @click="onSubmit" size="mini">保存
                                    </el-button>

                                </el-col>
                                <!--<el-col class="pull-left" :lg="2">
                                    <vxe-toolbar class="pull-left" style=" height: 42px; " custom ref="xToolbar2">
                                    </vxe-toolbar>
                                </el-col>-->
                               <!-- <el-col style="margin-bottom: 10px;" :lg="16" :md="18" :sm="18">
                                </el-col>-->
                            </el-row>
                        </div>
                    </el-col>
                    <!--<el-col :lg="1"></el-col>-->
                    <!--@tab-click="$refs.xGrid.searchSpecificationRowTableData(selectTab,this.$refs.xGrid.getCurrentRecord())"-->
                    <el-col :md="showFrom ? 15 : 24" :sm="24" style="padding-left: 10px;">
                        <el-tabs v-model="selectTab" size="mini" @tab-click="handleClickTab">
                            <el-tab-pane label="显示最近进价" name="tab1"></el-tab-pane>
                            <el-tab-pane label="显示报价历史" name="tab2"></el-tab-pane>
                            <el-tab-pane label="显示调仓情况" name="tab3"></el-tab-pane>
                        </el-tabs>
                        <div v-show="selectTab === 'tab1'">
                            <vxe-grid
                                    id="JCGrid"
                                    ref="JCGrid"
                                    align="center"
                                    border
                                    resizable
                                    keep-source
                                    highlight-current-row
                                    :height="200"
                                    :sync-resize="selectTab"
                                    size="mini"
                                    :columns="tableColumnJC"
                                    :data="tableDataJC"
                                    :row-class-name="tableRowClassName"
                                    show-overflow
                                    :mouse-config="{selected: false}"
                                    :custom-config="{storage:true}"
                                    :keyboard-config="{isArrow: true, isDel: false, isEnter: true, isTab: true, isEdit: false,enterToTab:true}">
                            </vxe-grid>
                        </div>

                        <div v-show="selectTab === 'tab2'">
                            <vxe-grid
                                    id="BJGrid"
                                    ref="BJGrid"
                                    align="center"
                                    border
                                    resizable
                                    keep-source
                                    highlight-current-row
                                    :height="200"
                                    size="mini"
                                    :columns="tableColumnBJ"
                                    :data="tableDataBJ"
                                    :sync-resize="selectTab"
                                    :row-class-name="tableRowClassName"
                                    show-overflow
                                    :mouse-config="{selected: false}"
                                    :custom-config="{storage:true}"
                                    :keyboard-config="{isArrow: true, isDel: false, isEnter: true, isTab: true, isEdit: false,enterToTab:true}">
                            </vxe-grid>
                        </div>

                        <div v-show="selectTab === 'tab3'">
                            <vxe-grid
                                    id="HCGrid"
                                    ref="HCGrid"
                                    align="center"
                                    border
                                    resizable
                                    keep-source
                                    highlight-current-row
                                    :height="200"
                                    size="mini"
                                    :columns="tableColumnHC"
                                    :data="tableDataHC"
                                    :row-class-name="tableRowClassName"
                                    :sync-resize="selectTab"
                                    show-overflow
                                    :mouse-config="{selected: false}"
                                    :custom-config="{storage:true}"
                                    :keyboard-config="{isArrow: true, isDel: false, isEnter: true, isTab: true, isEdit: false,enterToTab:true}">
                            </vxe-grid>
                        </div>

                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <MnGrid v-on:childBySelectTab="childBySelectTab" v-on:childByTableDataJC="childByTableDataJC" v-on:childByTableDataBJ="childByTableDataBJ"
                                v-on:childByTableDataHC="childByTableDataHC" v-on:childByTableDataKD="childByTableDataKD"
                                ref="xGrid" id="ProductQuotationId" :height="(this.getViewHeight() - 550)+''"
                                :select-tab="selectTab" :table-column="tableColumn" :table-data-main="tableDataMain" :form-data="formData" :allow-create="false"
                                search-type="ProductQuotation" :search-all="true"></MnGrid>
                    </el-col>

                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                keep-source
                                highlight-current-row
                                id="footerGrid"
                                ref="footerGrid"
                                :height="(this.getViewHeight() - 560)+''"
                                align="center"
                                size="mini"
                                :columns="tableColumnKD"
                                :data="tableDataKD"
                                :row-class-name="tableRowClassName"
                                @cell-dblclick="$_cellClickOpenBill"
                                :cell-class-name="$_cellClassNameSummary"
                                show-overflow
                                :mouse-config="{selected: false}"
                                :custom-config="{storage:true}"
                                :keyboard-config="{isArrow: true, isDel: false, isEnter: true, isTab: true, isEdit: false,enterToTab:true}">
                        </vxe-grid>
                    </el-col>
                </el-row>
                <AddCompany ref="AddCompany"></AddCompany>
            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'

    import MnGrid from "../../components/MnGrid";
    import AddCompany from "../../components/AddCompany";
    export default {
        components: {AddCompany,MnGrid},
        data() {
            return {
                pageType:'产品报价单',
                tableDataJC: [],
                tableDataBJ: [],
                tableDataHC: [],
                tableDataKD: [],
                isAddBill: true,
                selectTab: 'tab1',
                specificationList: [],
                filterData: {
                    bill_id_type: '',
                    specification: '',
                    customer_name:'',
                    state:"激活"
               },
                loading:false,
                formData: {
                    bill_date: new Date(),
                    bill_id_type: 'BJ',
                    bill_id: '',
                    customer: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_theoretical_weight: '',
                    total_amount: '',
                    total_number: '',
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '报价单',
               },
                tableColumn: [
                    {type: 'seq', title: '序号',className: 'unEditor', width: 40},
                    {
                        field:'specification',
                        width: 150, title: '实际规格',
                        titleHelp: {message: '库存0不显示；只显示方管和矩管'},
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'category',
                        visible: true,
                        width: 50,
                        title: '类别',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'price', width: 80, title: '本次报价',
                        titleHelp: {message: '单位(吨) 单价= 吨价\n单位(支) 单价= 每支单价\n支持行内数学运算\n例如：10+20(回车)得30'},
                        slots: {edit: 'quotation_price_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'actual_thickness', width: 50, title: '实厚',
                        slots: {edit: 'actual_thickness_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'single_weight_str',
                        width: 100,
                        title: '支重(可文本)',
                        slots: {edit: 'single_weight_str_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field:'single_count', width: 52, title: '支数',
                        slots: {edit: 'single_count_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'unit',
                        width: 45,
                        title: '单位',
                        slots: {edit: 'unit_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'weighing_heavy',
                        width: 74,
                        title: '过磅重(吨)',
                        className: 'unEditor',
                        titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                        slots: {edit: 'weighing_heavy_edit'},
                        editRender: {autofocus: 'input.el-input__inner',enabled:true}
                   },
                   {field:'amount_of_product',visible: false,className: 'unEditor', width: 90, title: '产品金额', titleHelp: {message: '单位(支) 产品金额= 数量*本次报价'}},
                    /*{
                        field:'single_price', width: 70, title: '本次报价', editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.singleCountEvent}
                       }
                   },*/


                    /*{
                        field:'actual_thickness',
                        title: '实厚', width: 60,
                        editRender: {name: 'ElInputNumber', props: {controls: false}}
                   },*/

                    /*{field:'single_weight', width: 60, title: '支重',
                        editRender: {name: 'ElInputNumber', props: {controls: false}}
                   },*/
                    //, editRender: {name: 'ElInputNumber', props: {controls: false}}
                    {field:'recently_price',visible: false,className: 'unEditor', width: 60, title: '最近单价'},
                    {field:'total_count',className: 'unEditor', width: 60, title: '库存'},
                    {field:'tons_of_price',className: 'unEditor', width: 60, title: '吨价', visible: false},
                    {field:'total_count_single_lock',className: 'unEditor', width: 60, title: '留货', titleHelp: {message: '已锁定的库存量'}},
                    {field:'warehouse4',className: 'unEditor', width: 60, title: '厂一'},
                    {field:'warehouse5',className: 'unEditor', width: 60, title: '厂二'},
                    {field:'warehouse1',className: 'unEditor', width: 60, title: '仓一'},
                    {field:'warehouse2',className: 'unEditor', width: 60, title: '仓二'},
                    {field:'warehouse3',className: 'unEditor', width: 60, title: '仓三'},
                    {field:'warehouse9',className: 'unEditor', width: 60, title: '仓四'},
                    {field:'warehouse7',className: 'unEditor', width: 60, title: '新门市'},
                    {field:'registrar_last',className: 'unEditor', width: 70, title: '记录员'},
                    {field:'customer',className: 'unEditor', width: 80, title: '客户'},
                    {field:'record_date',className: 'unEditor', width: 95, title: '记录日期'},
                    {field:'single_count_quotation',className: 'unEditor', width: 60, title: '报价数量'},
                    {
                        field:'theoretical_weight',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                   },{
                        field:'theoretical_weight_single',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(吨)'},
                        title: '理论支重'
                   },
                    {field:'single_price', width: 100, className: 'unEditor',title: '报价(每支单价)'},
                    {
                        field:'single_weight',
                        width: 50,
                        className: 'unEditor',
                        titleHelp: {message: '支重=过磅重/支数\n(kg)'},
                        title: '支重'
                   },

                ],
                tableDataMain: [],
                unit: [
                    {id: 1, unit: '吨'},
                    {id: 2, unit: '支'}
                ],
                category: [
                    {id: 1, category: '方管'},
                    {id: 2, category: '矩管'}
                ],
                specification: [],
                footerData: [],
                activeName: 'first',
                //调仓情况
                tableColumnHC: [
                    {
                        field:'bill_stock_date', width: 110, title: '日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                    },
                    {field:'bill_id_stock', width: 150, title: '单号'},
                    {field:'warehouse', width: 100, title: '调入仓库'},
                    {field:'warehouse_out', width: 100, title: '调出仓库'},
                    {field:'single_count', width: 100, title: '支数'},
                    {field:'single_weight', width: 100, title: '支重'},
                    {field:'remark_stock', width: 150, title: '备注'},
                    {field:'batch_number', width: 140,title: '批号'},
                    {field:'full_thick', width: 50, title: '足厚'},
                    {field:'specification', width: 150,title: '实际规格'}
                ],
                //显示报价历史
                tableColumnBJ: [
                    {field:'company_name', width: 100, title: '客户'},
                    {field:'registrar', width: 70, title: '记录员'},
                    {
                        field:'bill_stock_date', width: 110, title: '报价日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy年MM月dd日')
                       }
                    },
                    {field:'single_price', width: 100, title: '报价(每支单价)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                    },
                    {field:'single_count', width: 100, title: '报价数量'},
                    {field:'amount_of_product', width: 100, title: '产品金额'},
                    {field:'full_thick', width: 50, title: '足厚'},
                    {field:'specification', width: 150,title: '实际规格'},

                ],
                tableColumnJC: [
                    {field:'company_name',width: 150, title: '供应商'},
                    {
                        field:'bill_stock_date',
                        width: 68,
                        title: '进仓日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yy.MM.dd')
                       }
                   },
                    {field:'category', width: 50, title: '类别'},
                    {field:'warehouse', width: 50, title: '仓库'},
                    {field:'single_count', width: 50, title: '支数'},
                    {field:'wbn_single_count', width: 80, title: '可用支数',
                        titleHelp: {message: '批号支数'}
                    },
                    {width: 60,title: '支价',
                        titleHelp: {message: '(含税支价)\n报价单支价 = (含税吨价) * 支重'},
                        formatter: ({row}) => {
                            return this.$XEUtils.commafy(row.tons_of_price_bj && row.single_weight ? this.$XEUtils.multiply(row.tons_of_price_bj,this.$XEUtils.round(this.$XEUtils.divide(row.single_weight ,1000), 5)) : '', {digits: 2})
                       }
                    },
                    {
                        field:'tons_of_price_bj', width: 80, title: '吨价',
                        /*titleHelp: {message: '(含税吨价)\n报价单吨价 = （（ 产品金额（含税） + 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 外省运费（含税））÷ 过磅重 ） + 10元'},*/
                        titleHelp: {message: '(不含税吨价)\n报价单吨价 = （（ 磅费 + 风割费 + 装车费 + 中转费 + 运费 ）÷ 过磅重 ） + 10元'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                    },
                    {field:'remark_stock', width: 100, title: '备注'},
                    {field:'full_thick', width: 50, title: '足厚'},
                    {field:'single_weight', width: 80, title: '支重',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(this.$XEUtils.divide(cellValue,1000), {digits: 5})
                       }
                    },
                    {field:'is_order', width: 50, title: '订货'/*,
                        formatter: ({cellValue}) => {
                            if(cellValue == null || cellValue == '' ){
                                return '否'
                            }else{
                                return '是'
                            }
                        }*/
                    },
                    {field:'limited_price', width: 80, title: '限价',
                        formatter: ({row,cellValue}) => {
                            if(row.is_order == '是'){
                                return ''
                            }else{
                                return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                            }
                        }
                    },
                    {field:'cost_price', width: 80, title: '成本',
                        formatter: ({row,cellValue}) => {
                            if(row.is_order == '是'){
                                return ''
                            }else{
                                return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                            }
                        }
                    },
                    {
                        field:'create_date',
                        width: 68,
                        title: '限价日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yy.MM.dd')
                        }
                    },
                    {field:'tax_tate_stock', width: 50, title: '含税',
                        formatter: ({cellValue}) => {
                            if(!this.isBlankVue(cellValue)){
                                return '是'
                           }
                       }
                   },
                    {field:'pounds_fees', width: 50, title: '磅费'},
                    {field:'wind_cut_fees', width: 50, title: '风割费'},
                    {field:'loading_car_fees', width: 50, title: '装车费'},
                    {field:'transfer_fees', width: 50, title: '中转费'},
                    {field:'freight_fees', width: 50, title: '运费'},
                    {field:'registrar', width: 55, title: '记录员'},
                    {field:'stock_type', width: 80, title: '类型'},
                    {field:'specification',width: 150,title: '实际规格'},
                    {field:'batch_number', width: 140,title: '批号'},
                    {field:'bill_id_stock', width: 160,title: '单号'},
                ],
                tableColumnKD: [
                    {type: 'seq', title: '序号', width: 40},
                    {field:'company_name', width: 240, title: '客户'},
                    {field:'part_order',visible: false, width: 55, title: '开单员'},
                    {field:'registrar', width: 55, title: '记录员'},
                    {field:'sales_man', width: 70, title: '业务员'},
                    {field:'category', width: 50, title: '类别'},
                    {
                        field:'bill_stock_date',
                        width: 68,
                        title: '销售日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yy.MM.dd')
                       }
                   },
                    {field:'actual_ton_price', width: 80, title: '实际销售吨价', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },
                    {field:'tons_of_price_practical', width: 70, title: '实际吨价'},
                    {field:'actual_a_price', width: 80, title: '实际销售支价', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },
                    {field:'tax_tate_stock', width: 50, title: '含税',
                        formatter: ({cellValue}) => {
                            if(!this.isBlankVue(cellValue)){
                                return '是'
                           }
                       }
                   },
                    {field:'warehouse_out', width: 50, title: '调出仓库'},
                    {field:'single_count', width: 70, title: '支数'},
                    {field:'theory_weight', width: 70, title: '理计'},
                    {field:'remark_stock', width: 100, title: '备注'},
                    {field:'single_weight', width: 80, title: '支重'},
                    {field:'stock_bill_date_dd', width: 80, title: '订单日期',formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yy.MM.dd')
                        }
                    },
                    {field:'full_thick', width: 50, title: '足厚'},
                    {field:'polish_roll', width: 100, title: '打磨驳口'},
                    {field:'specification', width: 150, title: '实际规格'},
                    {field:'batch_number', width: 140,title: '批号'},
                    {field:'bill_id_stock', width: 170, title: '销售开单单号'},
                ],
           }
       },
        methods: {
            childBySelectTab: function (childValue) {
                // childValue就是子组件传过来的值
                //console.log('子传父',childValue)
                this.selectTab = childValue
           },
            childByTableDataJC: function (childValue) {
                // childValue就是子组件传过来的值
                //console.log('子传父',childValue)
                this.tableDataJC = childValue
           },
            childByTableDataBJ: function (childValue) {
                // childValue就是子组件传过来的值
                this.tableDataBJ = childValue
           },
            childByTableDataHC: function (childValue) {
                // childValue就是子组件传过来的值
                this.tableDataHC = childValue
           },
            childByTableDataKD: function (childValue) {
                // childValue就是子组件传过来的值
                this.tableDataKD = childValue
           },
            //生成销售订单
            createDd() {

           },
            //保存出库单
            onSubmit() {
                this.loading = true
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let url = '';
                if (this.isAddBill) {
                    url = '/admin/productQuotation/saveVue';
               } else {
                    url = '/admin/productQuotation/updateVue';
               }
                this.formData.only_bill_date=this.formData.bill_date
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    this.loading = false
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.$router.push({path: "/productQuotation", query: {bill_id: this.formData.bill_id}})
                        //billID
                        /*
                        this.tableDataMain = this.$_createRowData(response.data.stocks);*/
                   } else {
                        this.loading = false
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    this.loading = false
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });
           },
            searchWarehouseBill() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.loading = true;
                this.$axios({
                    method: 'post',
                    url: '/admin/productQuotation/search',
                    params: {
                        bill_id: this.formData.bill_id,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    this.loading = false;
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    loading.close();
               });
           },
            editMethod({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
           tableRowClassName({row,rowIndex}) {
               if(row.stock_type == 'JC' && row.wbn_single_count <= 0){
                   return 'red-row';
               }else if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            //父调用子方法
            handleClickTab(){
                //this.$refs.baseGird.getActiveRecord().row
                //console.log(this.$refs.xGrid.$refs.baseGird.getActiveRecord());
                //console.log(this.$refs.xGrid.$refs.baseGird.$refs.xTable.getActiveRecord());
                this.$refs.xGrid.handleClickTab(this.selectTab);
            }
       },
        created() {
            this.showFrom = false;
            this.formData.bill_id = this.$route.query.bill_id;
            //this.$_searchCustomerList('');
            //this.$_searchCategoryList('');
            //this.$_searchUnitList('');
            if (this.formData.bill_id == null || this.formData.bill_id == '') {
                var list = [];
                this.tableDataMain = this.$_createRowData(list);
           } else {
                this.searchWarehouseBill();
           }
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.$refs.HCGrid.connect(this.$refs.xToolbar3)
                this.$refs.BJGrid.connect(this.$refs.xToolbar4)
                this.$refs.JCGrid.connect(this.$refs.xToolbar5)
                if(this.$_getActionKeyListBoolean(['/productQuotation/hasEveryPrice'])){
                    this.$refs.JCGrid.showColumn(this.$refs.JCGrid.getColumnByField('limited_price'))
                    this.$refs.JCGrid.showColumn(this.$refs.JCGrid.getColumnByField('cost_price'))
                    this.$refs.JCGrid.showColumn(this.$refs.JCGrid.getColumnByField('create_date'))
                }else{
                    this.$refs.JCGrid.hideColumn(this.$refs.JCGrid.getColumnByField('limited_price'))
                    this.$refs.JCGrid.hideColumn(this.$refs.JCGrid.getColumnByField('cost_price'))
                    this.$refs.JCGrid.hideColumn(this.$refs.JCGrid.getColumnByField('create_date'))
                }
                //console.log('is_show_company_name',this.$store.getters.getLoginAccount.is_show_company_name)
                if (!this.$store.getters.getLoginAccount.is_show_company_name){
                    this.$refs.footerGrid.hideColumn(this.$refs.footerGrid.getColumnByField('company_name'))
                }else{
                    this.$refs.footerGrid.showColumn(this.$refs.footerGrid.getColumnByField('company_name'))
                }
           })
       },
        computed: {
           /* isShowCompany: function () {
                if (this.$store.getters.getLoginAccount.is_show_company_name) {
                    return true;
                } else {
                    return false;
                }
            },*/
        },
   };


</script>

<style scoped>
    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }
/*    /deep/ table .vxe-body--column{
        font-size: 14px;
   }*/
    /deep/ .vxe-table--render-default.size--mini .vxe-body--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-footer--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-header--column.col--ellipsis, .vxe-table--render-default.vxe-editable.size--mini .vxe-body--column{
        height: 28px;
   }

</style>
